import React from "react";
import { FaTools } from "react-icons/fa";
import { MdOutlineDeveloperMode } from "react-icons/md";
import { CgWebsite } from "react-icons/cg";
import { SiJirasoftware } from "react-icons/si";
import { FaCode } from "react-icons/fa";

export const services = [
    {
        id: 1,
        icon: <MdOutlineDeveloperMode />,
        title: "mobile applications",
        info: "We are committed to building user friendly and highly reliable mobile applications that ensure your business is globally accessible to your target audience.",
    },
    {
        id: 2,
        icon: <SiJirasoftware />,
        title: "software & SaaS",
        info: "We understand that software is an integral part of any company or organization that is looking to thrive. That is why we focus on building software systems that are not only technically efficient but also user friendly, thus boosting productivity in your business operations.",
    },
    {
        id: 3,
        icon: <FaTools />,
        title: "hardware maintenance",
        info: "Fully functional and reliable hardware and software systems are critical to optimum productivity. That is why we are committed to providing maintenance support to both hardware and software systems ensuring that your business is always up and running and using the latest technologies.",
    },
    {
        id: 4,
        icon: <CgWebsite />,
        title: "websites & webapps",
        info: "Digital presence is a must for any business that intends to thrive in the information age. At Encode Junction we will help you gain a competitive edge by building websites and web applications that guarantee your company or organization always stands out.",
    },
    {
        id: 5,
        icon: <FaCode/>,
        title: "systems & API integration",
        info: "Encode Junction excels in seamless systems & API integration services, connecting diverse technologies for optimal business functionality. Our expert team ensures efficient communication and data flow across platforms, enhancing productivity and providing tailored solutions for your technological integration needs."
    }
];
