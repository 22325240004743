import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import { services } from "../../data/companyservices/services";
import Service from "./Service";
import "./ourServices.scss";
import { Digitize } from "../components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const OurServices = () => {
    const [serviceInfo, setServiceInfo] = useState(services);

    gsap.registerPlugin(ScrollTrigger);
    const servicesRef = useRef();
    const digi = useRef();
    const tl = gsap.timeline();

    useLayoutEffect(() => {
        // services section animation
        let ctx = gsap.context(() => {
            tl.from(".services__container-title > h2", {
                scale: 2,
                opacity: 0,
                delay: 1,
                duration: 3,
                letterSpacing: "-1.5em",
                ease: "bounce",
            }).from(
                ".services__card",
                {
                    x: 200,
                    opacity: 0,
                    duration: 1,
                    ease: "power1.out",
                    stagger: 1,
                },
                "+=0.75"
            );

            // digitize container animation
            gsap.from(digi.current, {
                opacity: 0,
                duration: 3,
                xPercent: -100,
                ease: "power1.easeInOut",
                scrollTrigger: {
                    trigger: digi.current,
                    toggleActions: "restart none none none",
                },
            });

            // services container indicator animation
            // gsap.fromTo(
            //     ".indicators",
            //     {
            //         y: -10,
            //         repeat: -1,
            //         yoyo: true,
            //     },
            //     {
            //         y: 10,
            //         color: "#ffc804",
            //         repeat: -1,
            //         yoyo: true,
            //     }
            // );
        }, servicesRef);

        return () => ctx.revert();
    }, []);

    return (
        <div className="services__container" ref={servicesRef}>
            {/* <div className="services__container-title ">
                <h2>What We Do</h2>
            </div> */}
            <div className="services__cards" ref={servicesRef}>
                {serviceInfo.map((service) => {
                    return (
                        <Service
                            key={service.id}
                            service={service}
                            serviceInfo={serviceInfo}
                        />
                    );
                })}
            </div>
            {/* <br />
            <div ref={digi}>
                <Digitize />
            </div> */}
        </div>
    );
};

export default OurServices;
