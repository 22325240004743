import React, { useRef, useState, useEffect } from "react";
import { gsap } from "gsap";
import { HiChevronDoubleDown, HiInformationCircle } from "react-icons/hi";

function Service({ service, serviceInfo }) {
    const [toggleService, setToggleService] = useState(false);
    const test = useRef();
    const infoRef = useRef();
    const infoSubtext = useRef();

    const handletoggle = (id) => {
        serviceInfo.map((service) => {
            service.id === id &&
                setToggleService((prevService) => !prevService);
        });
    };
    useEffect(() => {
        test.current = gsap.timeline({
            paused: true,
        });
        test.current
            .to(infoRef.current, {
                // className: "show-info",
                height: "auto",
                duration: 0.4,
                ease: "Power4.easeInOut",
            })
            .fromTo(
                infoSubtext.current,
                {
                    opacity: 0,
                    y: 50,
                    duration: 0.5,
                    ease: "Power4.easeInOut",
                },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.5,
                    ease: "Power4.easeInOut",
                }
            );
    }, []);

    useEffect(() => {
        toggleService ? test.current.play() : test.current.reverse();
    }, [toggleService]);

    return (
        <>
            <div className="services__card">
                <div className="services__card-icon">
                    <div className="services__card-icon-wrapper">
                        <p>{service.icon}</p>
                    </div>
                    <div className="services__card-icon-title-wrapper">
                        <div className="overline__center"></div>
                        <h3 className="services__card-icon-title ">
                            {service.title}
                        </h3>
                        <div
                            className="indicators"
                            onClick={() => handletoggle(service.id)}
                        >
                            <span>
                                <HiInformationCircle />
                            </span>
                            {/* <span>
                                <HiChevronDoubleDown />
                            </span> */}
                        </div>
                    </div>
                </div>
                <div className="services__card-info" ref={infoRef}>
                    <p
                        className="services__card-info-subtext"
                        ref={infoSubtext}
                    >
                        {service.info}
                    </p>
                </div>
            </div>
        </>
    );
}

export default Service;
