import { Client, Databases, Account } from 'appwrite'

export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID
export const DATABASE_ID = process.env.REACT_APP_DATABASE_ID
export const COLLECTION_ID_COOKIE_ANALYTICS = process.env.REACT_APP_COLLECTION_ID_COOKIE_ANALYTICS
export const APPWRITE_ENDPOINT = process.env.REACT_APP_APPWRITE_ENDPOINT

const client = new Client()

client
    .setEndpoint(APPWRITE_ENDPOINT)
    .setProject(PROJECT_ID)

export const databases = new Databases(client)
export const account = new Account(client)

export default client