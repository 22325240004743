import React from "react";
import './styles/bootstrap.min.css'
import './styles/Footer.css'

const Footer = () => {

    const closeContactSectionSm = () => {

        const footer = document.querySelector('.footer')

        footer.style.height = '3.5rem'

    }

    const closeContactSectionLg = () => {

        const footer = document.querySelector('.footer')

        footer.style.height = '3.5rem'

    }

    // Large screens
    const handleFooterMouseEnter = () => {

        const footer = document.querySelector('.footer')

        let screenWidth = (window.innerWidth > 0) ? window.innerWidth : window.screen.width

        // Show Footer Section
        if (screenWidth <= 480) {
            footer.style.height = '26.5em'
        } else {
            footer.style.height = '16em'
        }

    }

    // Large screens
    const handleFooterMouseLeave = () => {

        const footer = document.querySelector('.footer')

        footer.style.height = '3.5rem'

    }

    return (
        <>
            <div className="footer" onMouseEnter={handleFooterMouseEnter} onMouseLeave={handleFooterMouseLeave}>
                <div className="button"></div>
                <div className="footer-container">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5 mt-3 mb-5">
                                <div className="text-end mb-3">
                                    <i className="fa-solid fa-rectangle-xmark fa-2x gold-shade close-contact-section-sm" onClick={closeContactSectionSm}></i>
                                </div>
                                <h6>
                                <i className="fa-solid fa-location-crosshairs text-gold"></i>&nbsp;&nbsp;
                                    <span className="text-secondary location-text">
                                        Kensington, Kyanja, Kampala Uganda
                                    </span>
                                </h6>
                                <img src="https://res.cloudinary.com/dvrgkzgpm/image/upload/v1682435058/Encode%20Logo/logo__kmvsan.png" className="logo" alt="Logo" />
                            </div>
                            <div className="col-md-2 mt-5 mb-5 middle-column">
                                
                            </div>
                            <div className="col-md-5 mt-3 mb-5 text-end right-column-section">
                                <div className="text-end mb-2">
                                    <i className="fa-solid fa-rectangle-xmark fa-2x gold-shade close-contact-section-lg" onClick={closeContactSectionLg}></i>
                                </div>
                                <h5>
                                    <a href="https://www.facebook.com/profile.php?id=100090034530254/" target="_blank" rel="noreferrer">
                                        <i className="fa-brands fa-facebook-f text-gold"></i>
                                    </a>
                                    &nbsp;&nbsp;
                                    <a href="https://twitter.com/encode_junction/" target="_blank" rel="noreferrer">
                                        <i className="fa-brands fa-twitter text-gold"></i>
                                    </a>
                                    &nbsp;&nbsp;
                                    <a href="https://www.linkedin.com/in/encode-junction-082405268/" target="_blank" rel="noreferrer">
                                        <i className="fa-brands fa-linkedin-in text-gold"></i>
                                    </a>
                                    &nbsp;&nbsp;
                                    <a href="https://www.instagram.com/encodejunction256/" target="_blank" rel="noreferrer">
                                        <i className="fa-brands fa-instagram text-gold"></i>
                                    </a>
                                </h5>
                                <h6>
                                    <a href="https://wa.me/256786194747" className="text-decoration-none">
                                        <i className="fa-brands fa-whatsapp text-white"></i>&nbsp;<span className="text-gold">+256 (786) 194-747</span>
                                    </a> 
                                    {/* &nbsp;&nbsp;<span className="vertical-bar-footer">|</span>&nbsp;&nbsp;
                                    <br className="footer-line-break" /> */}
                                </h6>
                                <h6>
                                    <a href="mailto:info@encodejunction.com" className="text-decoration-none">
                                        <i className="fa-solid fa-envelope text-white"></i>&nbsp; <span className="text-gold">info@encodejunction.com</span>
                                    </a>
                                </h6>
                                <div className="mt-3">
                                    <a href="/cookiePolicy" className="text-decoration-none text-slate-400">Cookie Policy</a>
                                </div>
                                <p className="text-secondary mt-2.5 copyright-text">
                                    Encode Junction &copy; {new Date().getFullYear()} All Rights Reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer