import React from "react"
import { Outlet, Navigate } from "react-router-dom"
import { useAuth } from "../../utils/AuthContext"

const PrivateRoutes = () => {

    const { user } = useAuth()

    return (
        <>
            {
                user ? <Outlet /> : <Navigate to='/sudoPass/login' />
            }
        </>
    )

}

export default PrivateRoutes