import React from "react";
import { FaHandshake } from "react-icons/fa";
import { AiFillDollarCircle } from "react-icons/ai";
import { TbBulbFilled } from "react-icons/tb";
import { GiBrain } from "react-icons/gi";

export const choices = [
    {
        id: 1,
        icon: <FaHandshake />,
        name: "Reliable and Trustworthy",
    },
    {
        id: 2,
        icon: <AiFillDollarCircle />,
        name: "Affordable",
    },
    {
        id: 3,
        icon: <TbBulbFilled />,
        name: "Innovative",
    },
    {
        id: 4,
        icon: <GiBrain />,
        name: "Modern Design and Technology",
    },
];
