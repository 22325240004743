import React from 'react'
import './styles/ExpandableImageAccordion.css'

const ExpandableImageAccordion = ({ images }) => {

    console.log('Images')
    console.log(images)

    return (
        <>
            <ul className="accordion mt-5 mb-5" style={{marginLeft: "auto", marginRight: "auto"}}>
                {
                    images?.map((image) => {
                        return <li className="accordion__item" key={image?.id}>
                            <img src={image?.url} alt="Image" />
                            <div className="accordion__overlay"></div>
                        </li>
                    })
                }
            </ul>
        </>
    )
}

export default ExpandableImageAccordion