import React from 'react'

const SudoDash = () => {
    return (
        <>
            <div className="container">
                <h1 className="text-white">Dashboard</h1>
            </div>
        </>
    )
}

export default SudoDash 