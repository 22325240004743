import React, { useState } from 'react'
import './styles/HomePage.css'

import appRecords from '../../../data/AppGarden/apps/apps'
import AppCard from '../AppCard/AppCard'
import { FaSlidersH } from 'react-icons/fa'

const HomePage = () => {

    const [apps, setApps] = useState(appRecords)

    const handleSearchInputFocus = () => {
        document.querySelector('#search').placeholder = ''
    }

    const handleSearchInputBlur = () => {
        const searchElement = document.querySelector('#search')
        const searchValue = searchElement.value.trim()

        if (searchValue === '') {
            searchElement.placeholder = 'Search apps...'
        }
    }

    let checkedCategories = []

    const handleSearch = (e) => {
        const searchText = e.target.value.toLowerCase()

        if (checkedCategories.length === 0) {
            // If no category is selected, filter from the original list of apps
            const filteredApps = appRecords.filter((app) => {
                return app.name.toLowerCase().includes(searchText)
            })
            setApps(filteredApps)
        } else {
            // If categories are selected, filter from apps in those categories
            const filteredApps = appRecords.filter((app) => {
                return checkedCategories.some((checkBox) => {
                    return app.category === checkBox.id && app.name.toLowerCase().includes(searchText)
                })
            })
            setApps(filteredApps)
        }
    }

    const handleCategoryChange = (e) => {
        const checkBoxes = document.querySelectorAll('.check')
        checkedCategories = Array.from(checkBoxes).filter((checkBox) => checkBox.checked === true)

        if (checkedCategories.length === 0) {
            // If no category is checked, display all apps
            setApps(appRecords)
        } else {
            const filteredApps = appRecords.filter((app) => {
                // Filter apps based on checked categories
                return checkedCategories.some((checkBox) => app.category === checkBox.id)
            })
            setApps(filteredApps)
        }
    }

    return (
        <>
            <div className="app-garden-text-section text-center mt-40">
                <h4 className="mt-5 multi-color-text">Where Progressive Web Apps Flourish!</h4>
                <p className='text-white'>One app for all your devices</p>
            </div>
            <nav className="p-4 mb-6 mt-30">
                <div className="container max-w-6xl mx-auto flex flex-col sm:flex-row gap-8 items-center">
                    {/* Search Area */}
                    <div className="relative w-full">
                        <input 
                            type="search" 
                            id="search" 
                            className='bg-gray-700 text-white rounded-full p-2 pl-10 focus:w-full input-text-padding' 
                            placeholder='Search apps...' 
                            autoComplete='off' 
                            onFocus={handleSearchInputFocus} 
                            onBlur={handleSearchInputBlur} 
                            onInput={handleSearch}
                        />
                        <svg
                            className="absolute left-2 top-1/2 -translate-y-1/2" 
                            id="searchIcon" 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="20" 
                            height="20" 
                            viewBox="0 0 24 24" 
                            strokeWidth="2" 
                            stroke="white" 
                            fill="none" 
                            strokeLinecap="round" 
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                            <path d="M21 21l-6 -6" />
                        </svg>
                    </div>
                </div>
            </nav>
            <main className="flex flex-col md:flex-row mx-auto container max-w-6xl">
                <div className="space-y-4 p-2 w-full max-w-[10rem]">
                    <h3 className="text-xl text-gray-300"><FaSlidersH /> </h3>
                    <div id="filters-container" className="text-xl space-y-2">
                        <div>
                            <input type="checkbox" className='check' id="apps" onChange={handleCategoryChange} />
                            <label htmlFor="apps" className="text-white pl-2">Apps</label>
                        </div>
                        <div>
                            <input type="checkbox" className='check' id="games" onChange={handleCategoryChange} />
                            <label htmlFor="games" className="text-white pl-2">Games</label>
                        </div>
                    </div>
                </div>

                {/* Apps Wrapper */}
                <div id="apps-wrapper" className="w-full max-w-4xl mx-auto grid grid-cols-2 sm:grid-cols-3 xl-grid-cols-4 gap-6 place-content-center p-4">
                    

                    {/* <div className="columns-1 md:columns-2 lg:columns-2 mb-5"> */}
                        {
                            apps?.map((app) => {
                                return <AppCard app={app} keyValue={app?.id} key={app?.id} />      
                            })
                        }
                    {/* </div> */}
                </div>

            </main>
        </>
    )
}

export default HomePage