import React, { useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { Link, useLoaderData } from "react-router-dom";
import { about2 } from "../../assetFile/assets";
import "./header.scss";
import { Navbar, Brands } from "../components";
import { ai } from "../../assetFile/assets";
import "./styles/Header.css";
import RotatingText from "../RotatingText/RotatingText";
import OurServices from "../ourServices/OurServices";
import BackgroundAnimation from "../BackgroundAnimation/BackgroundAnimation";
import LandingPageContainer from '../AppGarden/LandingPageContainer/LandingPageContainer.jsx'

const Header = () => {
    const app = useRef();
    const heading = useRef();
    const list = heading.childNodes;
    const tl = gsap.timeline();
    useLayoutEffect(() => {

        (function() {
            let words = [
                'BRAND',
                'ORGANISATION',
                'STARTUP',
                'INSTITUTION',
                'SME'
            ]
    
            let i = 0
    
            setInterval(function () {
                let changingWordElement = document.getElementById('changingWord')
    
                fadeOut(changingWordElement, function () {
                    changingWordElement.innerHTML = words[i = (i + 1) % words.length]
                    fadeIn(changingWordElement)
                })
            }, 5000)
    
            const fadeOut = (element, callback) => {
                let opacity = 1
                let fadeOutInterval = setInterval(function () {
                    if (opacity > 0) {
                        opacity -= 0.1
                        element.style.opacity = opacity
                    } else {
                        clearInterval(fadeOutInterval)
                        callback()
                    }
                }, 100)
            }
    
            const fadeIn = (element) => {
                let opacity = 0
                let fadeInInterval = setInterval(function () {
                    if (opacity < 1) {
                        opacity += 0.1
                        element.style.opacity = opacity
                    } else {
                        clearInterval(fadeInInterval)
                    }
                }, 100)
            }
        })()

        let ctx = gsap.context(() => {
            tl.from(".header__container-image", {
                duration: 1,
                scale: 0.5,
                opacity: 0,
                repeat: -1,
                yoyo: true,
                // yPercent: -200,
            })
                .from(
                    [
                        heading?.current?.childNodes[0],
                        heading?.current?.childNodes[2],
                        heading?.current?.childNodes[1],
                        heading?.current?.childNodes[4],
                    ],
                    {
                        opacity: 0,
                        y: 100,
                        scale: 3,
                        duration: 2,
                        stagger: 1,
                        delay: 3,
                        ease: "power2.inOut",
                    },
                    "<"
                )
                .from([".header__subinfo", ".header__button"], {
                    opacity: 0,
                    y: 40,
                    ease: "circ.in",
                });
        }, app);

        return () => ctx.revert();
    }, []);

    

    return (
        <div className="header__container">
            <div className="header__wrapper" ref={app}>
                <div className="header__container-info">
                    <div className="mt-70">
                        <div className="centered-header text-align-center">
                            <h1 ref={heading} className="outline">
                                <span>WE </span>
                                <p className="mobile-padding"></p>
                                <span className="gold-shade no-outline">
                                    THINK-CREATE-INNOVATE{" "}
                                </span><br />
                                <p className="mobile-padding"></p>
                                <span>FOR YOUR</span>
                                <br />
                                <p className="mobile-padding"></p>
                                <span id="changingWord" className="gold-shade no-outline"></span>
                            </h1>
                        </div>
                    </div>
                    <BackgroundAnimation />
                </div>

                <br /><br />
                <div className="about-con">
                    <div className="abt-img">
                        <img src={ai} alt="we innovate" />
                    </div>
                    <div className="abt-txt">
                        <h1 className="head-txt gold-shade mb-5">About</h1>
                        <div className="intro-con">
                            <p>
                                {/* {" "} */}
                                Encode Junction is a team of dedicated and
                                proficient professionals founded with the dream
                                of developing solutions to technical problems
                                and building amazing products for our clients.
                            </p>
                            
                            <p>
                                We are dedicated to helping your business reach
                                a level of global impact by using a wide range
                                of technologies to digitize your ideas and make
                                them globally accessible.
                            </p>
                            <br />
                            {/* <div className="choice__container-info-button">
                                <button>
                                    <a
                                        href="/aboutUs"
                                        style={{
                                            color: "#ffffff",
                                            textDecoration: "none",
                                        }}
                                    >
                                        Learn More
                                    </a>
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
                <br />
                <div className="mt-4">
                    <OurServices />
                </div>
                <br />
                <Brands />
                
                <LandingPageContainer />
            </div>
        </div>
    );
};

export default Header;
