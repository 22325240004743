import React from 'react'

const CookiePolicy = () => {

    return (
        <>
            <br /><br />
            <div className='container mx-auto mt-5 pt-5 mb-5 pb-5'>
                <h2 className='font-extrabold text-white'>Cookie Policy</h2>
                <h4 className='font-semibold mt-5 text-gray-600'>Introduction</h4>
                <p className='text-slate-400'>This Cookie Policy explains how our website uses cookies and similar technologies to provide you with an enhanced browsing experience. By continuing to browse our website, you agree to our use of cookies as described in this policy.</p>
            
                <h4 className='font-semibold text-gray-600 mt-5'>What are Cookies?</h4>
                <p className='text-slate-400'>Cookies are small pieces of data stored on your device (computer or mobile device) when you visit a website. They are widely used to make websites work efficiently and to provide information to website owners.</p>
            
                <h4 className='font-semibold text-gray-600 mt-5'>How We Use Cookies</h4>
                <p className='text-slate-400'>We use cookies for various purposes, including:</p>
            
                <p className='text-slate-400'><span className='font-medium text-gray-600'>Essential Cookies:</span> These cookies are necessary for the website to function properly. They enable core functionality such as security, network management, and accessibility.</p>
            
                <p className='text-slate-400'><span className='font-medium text-gray-600'>Analytical/Performance Cookies:</span> These cookies allow us to analyze how visitors interact with our website, including which pages are visited most frequently and if users encounter any error messages. This helps us improve the performance and usability of our website.</p>
             
                <p className='text-slate-400'><span className='font-medium text-gray-600'>Functionality Cookies:</span> These cookies enable enhanced functionality and personalization, such as remembering your preferences or settings.</p>
             
                <p className='text-slate-400'><span className='font-medium text-gray-600'>Advertising/Targeting Cookies:</span> These cookies are used to deliver advertisements that are more relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns.</p>
             
                
                <h4 className='font-semibold text-gray-600 mt-5'>Types of Cookies We Use</h4>
                <p className='text-slate-400'><span className='font-medium text-gray-600'>Session Cookies:</span> These cookies are temporary and are deleted when you close your browser. They are used to maintain your session and enable certain features of the website.</p> 
            
                <p className='text-slate-400'><span className='font-medium text-gray-600'>Persistent Cookies:</span> These cookies remain on your device for a specified period or until you delete them. They are used to recognize you when you return to the website and remember your preferences.</p>
             
                <h4 className='font-semibold text-gray-600 mt-5'>Third-Party Cookies</h4>
            
                <p className='text-slate-400'>We may also use third-party cookies, which are cookies set by a domain other than our website. Third-party cookies are often used for advertising and analytics purposes. We have no control over these cookies and their use is governed by the privacy policies of the third-party services.</p>
            
                <h4 className='font-semibold text-gray-600 mt-5'>Managing Cookies</h4>
                <p className='text-slate-400'>You can control and manage cookies in various ways, including by adjusting your browser settings. Please note that blocking or deleting cookies may affect your browsing experience and functionality of our website.</p>
            
                <h4 className='font-semibold text-gray-600 mt-5'>Changes to this Cookie Policy</h4>
                <p className='text-slate-400'>We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically for any updates.</p>
            
                <h4 className='font-semibold text-gray-600 mt-5'>Contact Us</h4>
                <p className='text-slate-400'>If you have any questions or concerns about our use of cookies, please contact us at <a href='mailto:info@encodejunction.com' className='font-medium text-blue-300'>info@encodejunction.com</a>.</p>
            
            
                <h4 className='font-semibold text-gray-600 mt-5'>Last Updated</h4>
                <p className='text-slate-400'>This Cookie Policy was last updated on <span className='text-white font-medium'>Wednesday 7th February, 2024</span>.</p>
            </div>
        </>
    )
}

export default CookiePolicy