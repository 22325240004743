import React from "react";
import ReactDOM from "react-dom/client";
import "./globalStyles/index.scss";
import App from "./App";

import './index.css'

// Service Worker
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
