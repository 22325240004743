import React from 'react'
import './styles/LandingPageContainer.css'

const LandingPageContainer = () => {

    return (
        <>
            
            <div className="app-garden-container text-center">
                
                <img style={{margin: "0 auto"}} src="https://res.cloudinary.com/dvrgkzgpm/image/upload/v1704701232/AppGarden/Black%20Background/App_Garden_Logo_cropped_rvtlp5_98cbca.png" />

                <div className="app-garden-text-section">
                    <h4 className="mt-5 multi-color-text">Welcome to AppGarden - Where Progressive Web Apps Flourish!</h4>

                    <p className='mt-5 text-grey'>Unlock the potential of the web with AppGarden, the ultimate destination for Progressive Web Apps(PWAs). Created by Encode Junction, AppGarden is more than just a store - it's a haven for cutting-edge PWA applications designed to transform your digital experience.</p>
                </div>

                <section className="app-garden-preview-section mt-5 mb-5 pb-5">
                    <article className="app-garden-card striped-border">
                        <div className="app-garden-card-body">
                            <i className='fa-solid fa-rocket fa-2x multi-color-text mt-3 mb-3'></i>
                            <h4 className="app-garden-card-title">Explore Limitless Possibilities</h4>
                            <p className="app-garden-card-text text-grey">Dive into a diverse ecosystem of PWAs catering to every need and interest. Whether you're into productivity tools, entertainment, or innovative utilities, AppGarden has something exceptional waiting for you.</p>
                        </div>
                    </article>
                    <article className="app-garden-card striped-border">
                        <div className="app-garden-card-body">
                            <i className='fa-solid fa-globe fa-2x multi-color-text mt-3 mb-3'></i>
                            <h4 className="app-garden-card-title">Seamless Integration, Any Device</h4>
                            <p className="app-garden-card-text text-grey">Enjoy the freedom of PWA technology. AppGarden ensures that these dynamic applications seamlessly integrate into your digital life, accessible across various devices and platforms. Say goodbye to limitations, and embrace the future of web applications.</p>
                        </div>
                    </article>
                    <article className="app-garden-card striped-border">
                        <div className="app-garden-card-body">
                            <i className='fa-solid fa-magnifying-glass fa-2x multi-color-text mt-3 mb-3'></i>
                            <h4 className="app-garden-card-title">Discover Hidden Gems</h4>
                            <p className="app-garden-card-text text-grey">Uncover hidden gems crafted by talented developers. AppGarden shines a spotlight on innovative and lesser-known PWAs, giving you access to unique, groundbreaking solutions that enhance your digital lifestyle.</p>
                        </div>
                    </article>
                </section>

                <section className="mt-2 mb-5 pb-5">
                    <a href="/appGarden" className='py-4 px-4 mb-5 text-white text-decoration-none text-2xl striped-border'>Explore</a>
                </section>

            </div>
        </>
    )
}

export default LandingPageContainer