import React from 'react'

const AppCard = ({ app, keyValue }) => {
    return (
        <>
            {/* <div className='w-full flex items-center justify-center dark:bg-gray-900 pr-5 pt-5' key={keyValue}> */}

                {/* App Card */}
                <a href={app?.appUrl} className='text-decoration-none'>
                    <div className='relative max-w-2xl my-0 md:my-16 flex relative items-start space-y-4 sm:flex-row sm:space-y-0 sm:space-x-3 px-4 py-8 border-2 border-dashed border-gray-500 dark:border-gray-400 shadow-lg rounded-lg app'>
                        <span className='absolute text-xs font-medium top-0 left-0 rounded-br-lg rounded-tl-lg px-2 py-1 bg-primary-100 dark:bg-gray-900 text-white border-gray-400 dark:border-gray-400 border-b-2 border-r-2 border-dashed'>
                            pwa
                        </span>

                        <div className='w-full flex justify-center sm:justify-start sm:w-auto'>
                            <img className='object-cover w-20 h-full mt-3 mr-3 rounded-lg' src={app?.logoUrl} alt="" />
                        </div>
                        <div className='w-full sm:w-auto flex flex-col items-center sm:items-start'>
                            <p className='font-display mb-2 text-xl font-semibold text-white' itemProp='app'>
                                { app?.name }
                            </p>
                            <div className='mb-2 md:text-lg text-gray-400'>
                                <p>{app?.author}</p>
                            </div>
                            <div className='flex gap-4'>
                                <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418">
                                    </path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </a>
                
            {/* </div> */}
        </>
    )
}

export default AppCard