import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import apps from '../../../data/AppGarden/apps/apps'
import ExpandableImageAccordion from '../ExpandableImageAccordion/ExpandableImageAccordion'

const AppDetails = () => {

    const params = useParams()
    const [appDetails, setAppDetails] = useState()

    const { id } = params

    console.log(`App ID: ${id}`)

    console.log('Apps')
    console.log(apps)

    useEffect(() => {

        let filteredApp = apps.filter((app) => {
            return parseInt(app?.id) === parseInt(id)
        })

        console.log('Matched App')
        console.log(filteredApp)

        setAppDetails(filteredApp[0])

    }, [id])

    return (
        <>
            <div style={{marginTop: "200px", textAlign: "center", color: "#fff"}}>
                <span className="card-image">
                    <img src={appDetails?.logoUrl} style={{width: "70px", height: "70px", borderRadius: "20px", marginLeft: "20px"}} alt="Logo" />
                </span>
                <span style={{marginLeft: "20px"}}>{appDetails?.name}</span><br />
                <p style={{marginLeft: "20px", marginTop: "20px"}}>Developer: <span className="gold-shade">{appDetails?.author}</span></p>
            </div>
            
            <div className="mt-4 text-center">
                <a href={appDetails?.appUrl} className='btn btn-primary btn-sm'>Get the App</a>
            </div>

            <ExpandableImageAccordion images={appDetails?.mobileScreenshots} />
            
        </>
    )
}

export default AppDetails