import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { urlFor, client } from '../../client';
import './brands.scss';

const Brands = () => {
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        const brandsQuery = '*[_type == "brands"]';

        client.fetch(brandsQuery).then((data) => {
            setBrands(data);
        });
    }, []);

    return (
        <div className="brands-container mt-5">
            <h2 className="title gold-shade">BRANDS WE'VE WORKED WITH</h2>

            <div className="client-brands">
                {brands.map((brand) => (
                    <motion.div
                        whileInView={{ opacity: [0, 1] }}
                        transition={{ duration: 0.5, type: 'tween' }}
                        key={brand._id}
                    >
                        <img src={urlFor(brand.imgUrl)} alt={brand.name} loading="lazy"/>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};

export default Brands;
