const apps = [
    {
        id: "0000001",
        name: "Dictionary",
        appUrl: "https://allester-react-dictionary.netlify.app/",
        logoUrl: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705414827/AppGarden/App%20Icons/Encode%20Dictionary/logo512_wsjmlr.png",
        author: "Allester Jones Bukenya",
        company: "Encode Junction",
        category: "apps",
        mobileScreenshots: [
            {
                id: "0001",
                url: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705417699/AppGarden/App%20Screenshots/Encode%20Dictionary/Mobile%20Screens/img2_rvgfud.png"
            },
            {
                id: "0002",
                url: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705417698/AppGarden/App%20Screenshots/Encode%20Dictionary/Mobile%20Screens/img4_oxdxo2.png"
            },
            {
                id: "0003",
                url: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705417698/AppGarden/App%20Screenshots/Encode%20Dictionary/Mobile%20Screens/img5_jygo26.png"
            },
            {
                id: "0004",
                url: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705417698/AppGarden/App%20Screenshots/Encode%20Dictionary/Mobile%20Screens/img1_y1igyo.png"
            }
        ],
        tabletScreenshots: [
            {
                id: "0001",
                url: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705417785/AppGarden/App%20Screenshots/Encode%20Dictionary/Tablet%20Screens/img4_etcv9q.png"
            },
            {
                id: "0002",
                url: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705417784/AppGarden/App%20Screenshots/Encode%20Dictionary/Tablet%20Screens/img2_rz2itj.png"
            },
            {
                id: "0003",
                url: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705417784/AppGarden/App%20Screenshots/Encode%20Dictionary/Tablet%20Screens/img3_qv6bra.png"
            },
            {
                id: "0004",
                url: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705417784/AppGarden/App%20Screenshots/Encode%20Dictionary/Tablet%20Screens/img1_mulzvw.png"
            }
        ]
    },
    {
        id: "0000002",
        name: "Calculator",
        appUrl: "https://encode-calculator.netlify.app/",
        logoUrl: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705414893/AppGarden/App%20Icons/Encode%20Calculator/logo512_g2i2sz.png",
        author: "Allester Jones Bukenya",
        company: "Encode Junction",
        category: "apps",
        mobileScreenshots: [
            {
                id: "0001",
                url: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705419434/AppGarden/App%20Screenshots/Encode%20Calculator/Mobile%20Screens/img2_wklqgi.png"
            },
            {
                id: "0002",
                url: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705419433/AppGarden/App%20Screenshots/Encode%20Calculator/Mobile%20Screens/img1_pdstnc.png"
            }
        ],
        tabletScreenshots: [
            {
                id: "0001",
                url: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705419413/AppGarden/App%20Screenshots/Encode%20Calculator/Tablet%20Screens/img1_c2b1pg.png"
            },
            {
                id: "0002",
                url: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1705419414/AppGarden/App%20Screenshots/Encode%20Calculator/Tablet%20Screens/img2_ueilkc.png"
            }
        ]
    },
    {
        id: "0000003",
        name: "Notes-It",
        appUrl: "https://notes-it.netlify.app/",
        logoUrl: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1706877349/AppGarden/App%20Icons/Notes-It/noteefy_original_rna07a.png",
        author: "Allester Jones Bukenya",
        company: "Encode Junction",
        category: "apps",
        mobileScreenshots: [],
        tabletScreenshots: []
    },
    {
        id: "0000004",
        name: "Hangman",
        appUrl: "https://hangman-en.netlify.app/",
        logoUrl: "https://res.cloudinary.com/dvrgkzgpm/image/upload/v1708947325/AppGarden/App%20Icons/Hangman/crossword_zujjhy.png",
        author: "Allester Jones Bukenya",
        company: "Encode Junction",
        category: "games",
        mobileScreenshots: [],
        tabletScreenshots: []
    }
]

export default apps