import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cookies from 'js-cookie'
import { AuthProvider } from "./utils/AuthContext";
import {
    Header,
   // Choice,
   // Digitize,
   // OurServices,
    Projects,
    AppGardenHome,
    AppDetails,
    CookiePolicy,
    SudoRegister,
    SudoLogin,
    SudoDash,
} from "./components/components";
import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";
import Navbar from '../src/components/Navigation/Navbar'
import Footer from "./components/FooterSection/Footer"
import { ID, Permission, Role } from "appwrite";
import { COLLECTION_ID_COOKIE_ANALYTICS, DATABASE_ID, databases } from "./appWriteConfig";

function App() {

    useEffect(() => {

        const siteUrl = window.location.href
    
        window.cookieconsent.initialise({
            "palette": {
                "popup": {
                    "background": "#FFD700"
                },
                "button": {
                    "background": "#000000"
                }
            },
            "position": "bottom-right",
            "content": {
                "href": "https://encodejunction.com/cookiePolicy"
            }
        })

        // Function to get browser and OS
        const getBrowserAndOS = () => {
            const userAgent = navigator.userAgent
            let browser = 'Unknown'
            let os = 'Unknown'

            // Check for browser
            if (userAgent.indexOf('Firefox') > -1) {
                browser = 'Firefox'
            } else if (userAgent.indexOf('Chrome') > -1) {
                browser = 'Chrome'
            } else if (userAgent.indexOf('Safari') > -1) {
                browser = 'Safari'
            } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1) {
                browser = 'Internet Explorer'
            }

            // Check for OS
            if (userAgent.indexOf('Windows') > -1) {
                os = 'Windows'
            } else if (userAgent.indexOf('Mac') > -1 || userAgent.indexOf('Darwin') > -1) {
                os = 'Mac OS'
            } else if (userAgent.indexOf('Linux') > -1) {
                os = 'Linux'
            } else if (userAgent.indexOf('Android') > -1) {
                os = 'Android'
            } else if (userAgent.indexOf('iOS') > -1) {
                os = 'iOS'
            }

            return { browser, os }
        }

        // Function to get current time
        const getCurrentTime = () => {
            return new Date().toISOString()
        }

        // Function to handle page visit
        const handlePageVisit = async () => {
            const currentPage = window.location.href
            const ejVisitData = JSON.parse(Cookies.get('ejVisitData') || '[]')

            const { browser, os } = getBrowserAndOS()

            ejVisitData.push({
                page: currentPage,
                timestamp: getCurrentTime(),
                os: os,
                browser: browser
            })

            let payload = {
                page: currentPage,
                timestamp: getCurrentTime(),
                user_operating_system: os,
                user_browser: browser
            }

            let permissions = [
                Permission.write(Role.any())
            ]

            const nodeEnv = process.env.REACT_APP_NODE_ENV

            try {

                if (nodeEnv !== 'development') {
                    let response = await databases.createDocument(
                        DATABASE_ID,
                        COLLECTION_ID_COOKIE_ANALYTICS,
                        ID.unique(),
                        payload,
                        permissions
                    )
        
                    Cookies.set('ejVisitData', JSON.stringify(ejVisitData), {
                        expires: 30
                    })
                }
                
            } catch (error) {
                console.error(error)
            }
        }

        // On component mount, record page visit
        handlePageVisit()

        // Change page title dynamically
        if (siteUrl?.includes('/works')) {
            document.querySelector('title').textContent = 'Our Works | Encode Junction'
        } else if (siteUrl?.includes('/appGarden')) {
            document.querySelector('title').textContent = 'AppGarden | Encode Junction'
        } else {
            document.querySelector('title').textContent = 'Home | Encode Junction'
        }

    }, []) // Run only once on component mount

    return (
        <BrowserRouter>
            <AuthProvider>
                <div className="App">
                    <Navbar />
                    <Routes>
                        <Route index element={<Header />} />
                        <Route path="appGarden" element={<AppGardenHome />} />
                        <Route path="works" element={<Projects />} />
                        <Route path="appGarden/appDetails/:id" element={<AppDetails />} />
                        <Route path="cookiePolicy" element={<CookiePolicy />} />
                        <Route path="sudoPass/login" element={<SudoLogin />} />
                        <Route path="sudoPass/register" element={<SudoRegister />}/>
                        <Route element={<PrivateRoutes />}>
                            <Route path="sudoPass/dashboard" element={<SudoDash />} />
                        </Route>
                    </Routes>
                    <Footer />
                </div>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
