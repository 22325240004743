import { createContext, useState, useEffect, useContext } from 'react'
import { account } from '../appWriteConfig'
import { useNavigate } from 'react-router-dom'
import { ID } from 'appwrite'
import Swal from 'sweetalert2'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)

    const NODE_ENV = process.env.REACT_APP_NODE_ENV

    useEffect(() => {
        getUserOnLoad()
    }, [])

    const getUserOnLoad = async () => {
        try {

            const accountDetails = await account.get()
            
            setUser(accountDetails)

        } catch (error) {
            
            if (NODE_ENV === 'development') {
                console.error(error)
            }

        }
        setLoading(false)
    }

    const handleUserLogin = async (e, credentials) => {

        e.preventDefault()
        console.log('CREDENTIALS:', credentials)

        try {
            
            const response = await account.createEmailSession(credentials.email, credentials.password)

            const accountDetails = await account.get()
            setUser(accountDetails)

            navigate('/sudoPass/dashboard')

        } catch (error) {
            if (NODE_ENV !== 'development') {
                console.log(error)
            }
        }

    }

    const handleUserLogout = async () => {
        await account.deleteSession('current')
        setUser(null)
    }

    const handleUserRegister = async (e, credentials) => {
        e.preventDefault()

        if (credentials.password1 !== credentials.password2) {
            Swal.fire({
                icon: 'error',
                title: 'Passwords do not match!!',
                showConfirmButton: false,
                timer: 1500
            })
            return
        }

        try {

            let response = await account.create(
                ID.unique(),
                credentials.email,
                credentials.password1,
                credentials.name
            )

            await account.createEmailSession(credentials.email, credentials.password1)

            const accountDetails = await account.get()

            setUser(accountDetails)

            navigate('/sudoPass/dashboard')
            
        } catch (error) {
            if (NODE_ENV !== 'development') {
                console.log(error)
            }
            Swal.fire({
                icon: 'error',
                title: `${error.message}`,
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    const contextData = {
        user,
        handleUserLogin,
        handleUserLogout,
        handleUserRegister
    }

    return <AuthContext.Provider value={contextData}>
        { loading ? <p>Loading</p> : children }
    </AuthContext.Provider>

}

export const useAuth = () => {
    return useContext(AuthContext)
}

export default AuthContext