import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { urlFor, client } from "../../client";
//import { projects } from "../../data/projects/projects";
import "./projects.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import RotatingText from "../RotatingText/RotatingText";
import "./styles/Projects.css";
import BackgroundAnimation from "../BackgroundAnimation/BackgroundAnimation";

const Projects = () => {
    const [works, setWorks] = useState([]);
    const container = useRef();
    const projectTitle = useRef();

    const tl = gsap.timeline();

    useEffect(() => {
        const query = '*[_type == "works"]';

        client.fetch(query).then((data) => {
            setWorks(data);
        });

        gsap.to(projectTitle.current, {
            duration: 6,
            ease: "power4.out",
        });
    }, []);

    // useLayoutEffect(() => {
    //     let ctx = gsap.context(() => {
    //         tl.from(".project__wrapper", {
    //             opacity: 0,
    //             y: -50,
    //             ease: "power4.outInOut",
    //             stagger: 1,
    //         })
    //             .from(".project__title", {
    //                 y: 30,
    //                 opacity: 0,
    //             })
    //             .from(".project__image", {
    //                 opacity: 0,
    //                 duration: 1,
    //                 xPercent: -100,
    //             })
    //             .to(".project__image", {
    //                 "--width": "0%",
    //                 duration: 1.5,
    //                 ease: "Power5.easeInOut",
    //             })
    //             .from(".project__image img", {
    //                 scale: 1.5,
    //                 duration: 1.5,
    //                 delay: -1.5,
    //                 ease: "Power5.easeIn",
    //             });
    //     }, container);

    //     return () => ctx.revert();
    // }, [tl]);

    // testing component animation
    // gsap.registerPlugin(ScrollTrigger);
    // let sections = gsap.utils.toArray(".project__wrapper");
    // useEffect(() => {
    //     sections.forEach((section) => {
    //         let title = section.querySelector(".project__title");
    //         let imageWrapper = section.querySelector(".project__image");
    //         let image = section.querySelector(".project__image img");
    //         gsap.timeline({
    //             scrollTrigger: {
    //                 trigger: section,
    //                 start: "top center",
    //             },
    //         })
    //             .from(title, {
    //                 y: 30,
    //                 opacity: 0,
    //             })
    //             .from(imageWrapper, {
    //                 opacity: 0,
    //                 duration: 1,
    //                 xPercent: -100,
    //             })
    //             .to(imageWrapper, {
    //                 "--width": "0%",
    //                 duration: 1.5,
    //                 ease: "Power5.easeInOut",
    //             })
    //             .from(image, {
    //                 scale: 1.5,
    //                 duration: 1.5,
    //                 delay: -1.5,
    //                 ease: "Power7.easeIn",
    //             });
    //     });
    // }, []);

    //  create timelines inside hooks and store it in a ref
    // example
    // const test3= useRef();  -------the assign a time line to it
    // toggleActions ""
    const tlRef = useRef(null);
    const main_container = useRef();
    const itemsRef = useRef([]);
    itemsRef.current = [];
    gsap.registerPlugin(ScrollTrigger);
    let sections = gsap.utils.toArray(".project__wrapper");
    useLayoutEffect(() => {
        // console.log(sections);
        // console.log("it has rendered");
        let ctx = gsap.context(() => {
            // sections.forEach((section) => {
            itemsRef.current.map((section) => {
                let title = section.querySelector(".project__title");
                let imageWrapper = section.querySelector(".project__image");
                let image = section.querySelector(".project__image img");
                let color = section.getAttribute("data-color");

                gsap.to(main_container.current, {
                    backgroundColor: color,
                    ease: "Power4.easeIn",
                    duration: 3,
                    scrollTrigger: {
                        trigger: section,
                        immediateRender: false,
                        toggleActions: "play none none reverse",
                        start: "top center",
                    },
                });

                tlRef.current = gsap
                    .timeline({
                        scrollTrigger: {
                            trigger: section,
                            // markers: true,
                            start: "top center",
                            toggleActions: "play none none reverse",
                        },
                    })
                    .from(imageWrapper, {
                        opacity: 0,
                        duration: 1,
                        xPercent: -100,
                    })
                    .to(imageWrapper, {
                        "--width": "0%",
                        duration: 1.5,
                        ease: "Power5.easeInOut",
                    })
                    .from(image, {
                        scale: 1.5,
                        duration: 1.5,
                        delay: -1.5,
                        ease: "Power5.easeIn",
                    })
                    .from(title, {
                        y: 30,
                        opacity: 0,
                    });
            });
        }, container);
        return () => ctx.revert();
    }, [sections]);

    const addToRefs = (item) => {
        if (item) {
            itemsRef.current.push(item);
        }
    };
    return (
        <div className="projects__container" ref={main_container}>
            <div className="projects__container-title">
                <div className="bgAnimationContainer">
                <BackgroundAnimation />
                </div>
                <h2 ref={projectTitle} className="outline-projects">
                    Our
                    <span className="gold-shade no-outline-projects">
                        Works
                    </span>
                </h2>
                <p className="text-white">Scroll</p>
                
            </div>

            <div className="projects__wrapper" ref={container}>
                {/* removed mapping from sanity usestate and used locally imported data
                you can map the use state and see that the animation might stop working if data is fetched
                
                //uncomment *markers* in uselayOutEffect for more quick visuals */}
                {works.map((project, index) => (
                    <div
                        className="project__wrapper"
                        key={index}
                        ref={addToRefs}
                        data-color={project.theme}
                    >
                        <div className="project__image">
                            <a
                                href={project.projectLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={urlFor(project.imgUrl)}
                                    //src={project.image}
                                    alt={project.title}
                                    loading="lazy"
                                />
                            </a>
                        </div>
                        <div className="project__title">
                            <div>
                                <p>Our Client</p>
                                <h2>{project.title}</h2>
                            </div>
                            <div className="project__period">
                                <p>Year</p>
                                <h3>{project.year}</h3>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Projects;
