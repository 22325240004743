import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './styles/Navbar.css'

const Navbar = () => {

    const siteUrl = window.location.href

    useEffect(() => {

        const logoElement = document.querySelector('.nav-logo')
        const logoText = document.querySelector('.companyName')
        const body = document.querySelector('body')
        const menuWrap = document.querySelector('.menu-wrap')

        const currentUrl = window.location.href

        {
            currentUrl?.includes('/appGarden') ? (
                console.log('Welcome to AppGarden')
            ) : console.log('Welcome to Encode Junction')

            if (currentUrl?.includes('/appGarden')) {
                logoElement.src = 'https://res.cloudinary.com/dvrgkzgpm/image/upload/v1704701232/AppGarden/Black%20Background/App_Garden_Logo_cropped_rvtlp5_98cbca.png'
                logoText.style.display = 'none'

                // logoElement.style.width = '200px'
                // logoElement.style.height = '120px'
                logoElement.classList.add('nav-logo')

                document.body.style.backgroundColor = '#ffffff'
            }

            if (currentUrl?.includes('/sudoPass')) {
                menuWrap.style.display = 'none'
            }
        }

    }, [])

    const handleContactUsClick = (e) => {
        // #1: Close navigation
        const toggler = document.querySelector('.toggler')
        const footer = document.querySelector('.footer')
        const closeContactSectionLgButton = document.querySelector('.close-contact-section-lg')

        let screenWidth = (window.innerWidth > 0) ? window.innerWidth : window.screen.width

        // Uncheck toggler
        toggler.checked = false

        // #2: Show Footer section
        if (screenWidth <= 480) {
            footer.style.height = '26.5em'
        } else {
            closeContactSectionLgButton.style.display = 'inline-block'
            footer.style.height = '16em'   
        }

    }

    return (
        <>
            <div className="menu-wrap">
                <input type="checkbox" className="toggler" />
                <div className="hamburger">
                    <div></div>
                </div>
                <div className="menu">
                    <div>
                        <div>
                            <ul className="mt-5">
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>
                                    <a href="/works">Our Works</a>
                                </li>
                                <li>
                                    <a href="/appGarden">AppGarden</a>
                                </li>
                                <li>
                                    <a href="#!" onClick={handleContactUsClick}>Get in Touch</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='logo-container'>
                    <a href={siteUrl?.includes('/appGarden') ? '/appGarden' : '/'}>
                        <img
                            src="https://res.cloudinary.com/dvrgkzgpm/image/upload/v1682435058/Encode%20Logo/logo__kmvsan.png"
                            className="nav-logo"
                            alt="Logo"
                        />
                    </a>
                    <p className='gold-shade companyName'>Encode Junction</p>
                </div>
            </div>
        </>
    )
}

export default Navbar
