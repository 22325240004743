import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import { useNavigate, Link } from 'react-router-dom'

const SudoLogin = () => {

    const { user, handleUserLogin } = useAuth()
    const navigate = useNavigate()

    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    })

    useEffect(() => {
        if (user) {
            navigate('/')
        }
    }, [user, navigate])

    const handleInputChange = (e) => {
        let name = e.target.name
        let value = e.target.value

        setCredentials({
            ...credentials,
            [name]: value
        })
    }

    return (
        <>
            <div className="container text-white">
                <div className="mt-40">
                    <div className="row mt-5 pt-5">
                        <form onSubmit={(e) => {handleUserLogin(e, credentials)}}>
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="" className="block text-sm font-medium leading-6 text-white">
                                        Email
                                    </label>
                                    <div className="mt-2">
                                        <input type="text" name='email' id='email' class='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor="" className="block text-sm font-medium text-white leading-6 text-gray-900">
                                        Password
                                    </label>
                                    <div className="mt-2">
                                        <input type="password" name="password" id="" className="block w-full rounded-md border-0 py-1 5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <button type="submit" className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        Login
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SudoLogin