import React, { useState } from 'react'
import { useAuth } from '../../../utils/AuthContext'
import { Link } from 'react-router-dom'

const SudoRegister = () => {

    const { handleUserRegister } = useAuth()

    const [credentials, setCredentials] = useState({
        name: '',
        email: '',
        password1: '',
        password2: ''
    })

    const handleInputChange = (e) => {

        let name = e.target.name
        let value = e.target.value

        setCredentials({
            ...credentials,
            [name]: value
        })

    }

    return (
        <>
            <div className="container">
                <div className="mt-40">
                    <div className="row mt-5 pt-5">
                        <form onSubmit={(e) => {handleUserRegister(e, credentials)}}>
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label 
                                        htmlFor="" 
                                        className="block text-sm font-medium leading-6 text-white"
                                    >
                                        Name:
                                    </label>
                                    <input 
                                        type="text" 
                                        name='name' 
                                        id='name' 
                                        placeholder='Names...' 
                                        className='block w-full rounded-md border-0 py-1.6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' 
                                        value={credentials.name} 
                                        onChange={(e) => {handleInputChange(e)}}
                                    />
                                </div>
                                <div className="sm:col-span-3">
                                    <label 
                                        htmlFor="" 
                                        className='block text-sm font-medium leading-6 text-white'
                                    >
                                        Email:
                                    </label>
                                    <input 
                                        type="email" 
                                        name="email" 
                                        id="email" 
                                        placeholder='Email...' 
                                        className='block w-full rounded-md border-0 py-1.6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'  
                                        value={credentials.email} 
                                        onChange={(e) => {handleInputChange(e)}}
                                    />
                                </div>
                                <div className="sm:col-span-3">
                                    <label 
                                        htmlFor="" 
                                        className='block text-sm font-medium leading-6 text-white'
                                    >
                                        Password:
                                    </label>
                                    <input 
                                        type="password" 
                                        name="password1" 
                                        id="password1" 
                                        placeholder='Password...' 
                                        className='block w-full rounded-md border-0 py-1.6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'  
                                        value={credentials.password1} 
                                        onChange={(e) => {handleInputChange(e)}}
                                    />
                                </div>
                                <div className="sm:col-span-3">
                                    <label 
                                        htmlFor="" 
                                        className='block text-sm font-medium leading-6 text-white'
                                    >
                                        Confirm Password:
                                    </label>
                                    <input 
                                        type="password" 
                                        name="password2" 
                                        id="password2" 
                                        placeholder='Confirm Password...' 
                                        className='block w-full rounded-md border-0 py-1.6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'  
                                        value={credentials.password2} 
                                        onChange={(e) => {handleInputChange(e)}}
                                    />
                                </div>
                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <button 
                                        type="submit" className='rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible: outline-offset-2 focus-visible:outline-indigo-600'>
                                            Register
                                        </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SudoRegister